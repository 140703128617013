import {
  AccountBalanceOutlined,
  CandlestickChartOutlined,
  FullscreenOutlined,
  GppMaybeOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  InsightsOutlined,
  NewspaperOutlined,
  PlaylistPlayOutlined,
  RadarOutlined,
  ReorderOutlined,
  ScienceOutlined,
  SettingsOutlined,
  ShowChartOutlined,
  TableChartOutlined,
  TroubleshootOutlined,
  TuneOutlined,
} from '@mui/icons-material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import type { ReactNode } from 'react';

import { Correlation, Network, Plants, Playground, ServerError, SmoothLineChart } from '../icons';
import type { DistributiveOmit } from '../../type.utils.ts';
import isNil from 'lodash/fp/isNil';
import { MARKET_REGIME } from 'Permissions.ts';

const assetScreenerLink = '/app/market/asset-screener';
const quantLink = '/app/market/clusters';
const marketRegimesLink = '/app/market/regime';
const assetsLink = '/app/market/assets';
const correlationsLink = '/app/market/correlations';
const newsExplorerLink = '/app/market/news-explorer';
const portfolioDashboardLink = '/app/portfolio/dashboard';
const portfolioProfAndLossLink = '/app/portfolio/profit-loss';
const portfolioPositionsLink = '/app/portfolio/positions';
export const operationReportLink = '/app/operations/report';
const stressTestLink = '/app/copilot/stress-test';
const unifiedStressTestLink = '/app/copilot/unified-stress-test';
const optionsStressTestOldLink = '/app/copilot/options-stress-test-old';
const strategiesLink = '/app/copilot/strategies';
const operationOrdersLink = '/app/operations/orders';
const costBasisLink = '/app/operations/cost-basis';
export const portfolioRiskLink = '/app/portfolio/risk';
const portfolioOptimizerLink = '/app/copilot/portfolio-optimizer';
export const portfolioOptimizerLinkNewPortfolioOptimization =
  '/app/copilot/portfolio-optimizer/optimizations/new-portfolio-optimization';
export const portfolioOptimizerLinkNewStrategyOptimization =
  '/app/copilot/portfolio-optimizer/optimizations/new-strategy-optimization';
const portfolioBuilderLink = '/app/copilot/portfolio-builder';
const portfolioLabLink = '/app/copilot/lab';
const operationOldTransactionsLink = '/app/operations/transactions-old';
const operationTransactionsLink = '/app/operations/transactions';
const operationInvestmentsLink = '/app/operations/investments';
const operationTradingActivityLink = '/app/operations/trading-activity';
const reconciliationLink = '/app/operations/reconciliation';
export const notificationLink = '/app/copilot/notifications';
export const playgroundLink = '/app/playground';
export const managementLink = '/app/management';
export const termsAndPoliciesLink = '/app/terms-and-policies';

export type SingleMenuItem = {
  type: 'item';
  title: ReactNode;
  icon: ReactNode;
  link: string;
  isActive?: (activePath: string) => boolean;
  newTab?: boolean;
  new?: boolean;
} & ({ adminOnly?: true; groups?: never[] } | { groups: string[]; adminOnly?: false });

export interface GroupMenuItem {
  type: 'group';
  title: string;
  icon: ReactNode;
  items: DistributiveOmit<SingleMenuItem, 'type'>[];
}

export type MenuItem = SingleMenuItem | GroupMenuItem;
const joyFontSize = 'xl2';
const muiFontSize = 'medium';

export const menu: MenuItem[] = [
  {
    type: 'group',
    title: 'Portfolio',
    icon: <AccountBalanceWalletOutlinedIcon fontSize={muiFontSize} />,
    items: [
      {
        title: 'Overview',
        icon: <HomeOutlined fontSize={muiFontSize} />,
        link: portfolioDashboardLink,
      },
      {
        title: 'Positions',
        icon: <AccountBalanceOutlined fontSize={muiFontSize} />,
        link: portfolioPositionsLink,
      },
      {
        title: 'P&L',
        icon: <ShowChartOutlined fontSize={muiFontSize} />,
        link: portfolioProfAndLossLink,
      },
      {
        title: 'Risk',
        icon: <GppMaybeOutlined fontSize={muiFontSize} />,
        link: portfolioRiskLink,
      },
    ],
  },
  {
    type: 'group',
    title: 'Copilot',
    icon: <RadarOutlined fontSize={muiFontSize} />,
    items: [
      {
        title: 'Portfolio optimizer',
        icon: <TuneOutlined fontSize={muiFontSize} />,
        link: portfolioOptimizerLink,
        isActive: (activePath: string): boolean => activePath.startsWith(portfolioOptimizerLink),
      },
      {
        title: 'Stress test',
        icon: <SmoothLineChart fontSize={joyFontSize} />,
        link: stressTestLink,
      },
      {
        title: 'Options stress test',
        icon: <TroubleshootOutlined fontSize={muiFontSize} />,
        link: optionsStressTestOldLink,
      },
      {
        title: 'Unified stress test',
        icon: <TroubleshootOutlined fontSize={muiFontSize} />,
        link: unifiedStressTestLink,
      },
      {
        title: 'Portfolio builder',
        icon: <ScienceOutlined fontSize={muiFontSize} />,
        link: portfolioBuilderLink,
      },
      {
        title: 'Strategies',
        icon: <Network fontSize={joyFontSize} />,
        link: strategiesLink,
        adminOnly: true,
      },
      {
        title: 'Lab',
        icon: <ScienceOutlined fontSize={muiFontSize} />,
        link: portfolioLabLink,
      },
    ],
  },
  {
    type: 'group',
    title: 'Research',
    icon: <CandlestickChartOutlined fontSize={muiFontSize} />,
    items: [
      {
        title: 'Asset screener',
        icon: <FullscreenOutlined fontSize={muiFontSize} />,
        link: assetScreenerLink,
      },
      {
        title: 'Quant insights',
        icon: <InsightsOutlined fontSize={muiFontSize} />,
        link: quantLink,
        isActive: (activePath: string): boolean =>
          activePath.startsWith(quantLink) || activePath.startsWith(assetsLink),
      },
      {
        title: 'Market regime',
        icon: <InsightsOutlined fontSize={muiFontSize} />,
        link: marketRegimesLink,
        groups: [MARKET_REGIME],
      },
      {
        title: 'News explorer',
        icon: <NewspaperOutlined fontSize={muiFontSize} />,
        link: newsExplorerLink,
      },
      {
        title: 'Asset correlations',
        icon: <Correlation fontSize={joyFontSize} />,
        link: correlationsLink,
      },
    ],
  },
  {
    type: 'group',
    title: 'Operations',
    icon: <CandlestickChartOutlined fontSize={muiFontSize} />,
    items: [
      {
        title: 'Report',
        icon: <TableChartOutlined fontSize={muiFontSize} />,
        link: operationReportLink,
      },
      {
        title: 'Trading activity',
        icon: <TableChartOutlined fontSize={muiFontSize} />,
        link: operationTradingActivityLink,
      },
      {
        title: 'Cost basis',
        icon: <TableChartOutlined fontSize={muiFontSize} />,
        link: costBasisLink,
      },
      {
        title: 'Orders',
        icon: <PlaylistPlayOutlined fontSize={muiFontSize} />,
        link: operationOrdersLink,
      },
      {
        title: 'Transactions',
        icon: <ReorderOutlined fontSize={muiFontSize} />,
        link: operationOldTransactionsLink,
      },
      {
        title: 'Transactions',
        icon: <ReorderOutlined fontSize={muiFontSize} />,
        link: operationTransactionsLink,
        adminOnly: true,
      },
      {
        title: 'Venture investments',
        icon: <Plants fontSize={joyFontSize} />,
        link: operationInvestmentsLink,
      },
      {
        title: 'Reconciliation',
        icon: <ServerError fontSize={joyFontSize} />,
        link: reconciliationLink,
      },
    ],
  },
] satisfies MenuItem[];

export const footerMenu = [
  {
    type: 'item',
    title: 'Control center',
    icon: <SettingsOutlined fontSize={muiFontSize} />,
    link: managementLink,
  },
  {
    type: 'item',
    title: 'Playground',
    icon: <Playground fontSize={joyFontSize} />,
    link: playgroundLink,
    adminOnly: true,
  },
  {
    type: 'item',
    title: 'Terms and policies',
    icon: <HelpOutlineOutlined fontSize={muiFontSize} />,
    link: termsAndPoliciesLink,
  },
] satisfies MenuItem[];

export const isActive = (item: Pick<SingleMenuItem, 'isActive' | 'link'>, activePath: string): boolean => {
  return item.isActive ? item.isActive(activePath) : activePath.startsWith(item.link);
};

export const shouldShowMenuItem = (
  item: MenuItem,
  isAdmin: boolean,
  hasAccess: (group: string) => boolean
): boolean => {
  if (item.type === 'item') {
    if (isAdmin) {
      return true;
    }

    if (item.adminOnly) {
      return isAdmin;
    }

    if (isNil(item.groups) || item.groups.length === 0) {
      return true;
    }

    return item.groups.some((group) => hasAccess(group));
  }

  return item.items.some((subitem) =>
    shouldShowMenuItem(
      {
        ...subitem,
        type: 'item',
      },
      isAdmin,
      hasAccess
    )
  );
};
