import { createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import { type BigNumber, bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import GTable from 'components/technical/GTable/GTable';
import type { GColumnDef } from 'components/technical/GTable/GTable.props';
import { useScreenBreakpointDown } from 'components/technical/screenSizeUtils.ts';
import type { IPortfolioExposureRiskQuery } from 'generated/graphql';

import { formatCash, formatPercentage } from '../../formatter.utils';
import AssetLabel from '../../market/asset/AssetLabel';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import { SubAccountLabel } from '../account/SubAccountLabel.tsx';
import { useTheme } from '@mui/joy';

type SubAccountPosition = IPortfolioExposureRiskQuery['portfolio']['positions']['positions'][number];

export type PositionDetails = Omit<SubAccountPosition, 'derivative'> & {
  derivative: Omit<NonNullable<SubAccountPosition['derivative']>, '__typename'>;
};

type PortfolioOpenDerivativePositionListProps = {
  data: IPortfolioExposureRiskQuery;
  portfolioBalance: BigNumber;
};

const PortfolioOpenDerivativePositionList: FunctionComponent<PortfolioOpenDerivativePositionListProps> = ({
  data,
  portfolioBalance,
}) => {
  const theme = useTheme();
  const isXsScreen = useScreenBreakpointDown('sm');
  const positions = data.portfolio.positions.positions.filter((pos): pos is PositionDetails => !isNil(pos.derivative));

  const sortedAssetPositions = sortBy(
    (pos) => [pos.asset.symbol, bignumber(pos.derivative.amount).mul(-1).toNumber()],
    positions
  );

  const columnHelper = createColumnHelper<PositionDetails>();

  const columns: GColumnDef<PositionDetails>[] = [
    {
      header: 'Asset',
      cell: (props: CellContext<PositionDetails, unknown>) => (
        <AssetLabel asset={props.row.original.asset} link={false} format="short" />
      ),
      accessorFn: (pos) => pos.asset.symbol,
    },
    ...(!isXsScreen
      ? [
          columnHelper.display({
            header: 'Sub-account',
            cell: (props: CellContext<PositionDetails, unknown>) => (
              <SubAccountLabel size={IconVariant.LARGE} subAccount={props.row.original.subAccount} />
            ),
          }),
        ]
      : []),
    {
      header: 'Side',
      accessorFn: (pos) => capitalize(pos.derivative.side),
    },
    {
      header: 'Notional',
      cell: (props: CellContext<PositionDetails, number>) => formatCash(props.getValue()),
      accessorFn: (pos) => (pos.derivative.notional ? bignumber(pos.derivative.notional).toNumber() : undefined),
    },
    {
      header: 'Unrealized P&L',
      cell: (props: CellContext<PositionDetails, number>) => formatCash(props.getValue()),
      accessorFn: (pos) =>
        pos.derivative.unrealizedPnl ? bignumber(pos.derivative.unrealizedPnl).toNumber() : undefined,
    },
    {
      header: 'Balance %',
      meta: {
        headerTooltip: 'Contribution to balance',
      },
      cell: (props: CellContext<PositionDetails, number | undefined>): string | undefined => {
        const balanceContribution = props.getValue();

        return balanceContribution && Number.isFinite(balanceContribution)
          ? formatPercentage(balanceContribution)
          : undefined;
      },
      accessorFn: (pos): number | undefined => {
        if (!pos.derivative.balance) {
          return undefined;
        }
        const balanceContribution = bignumber(pos.derivative.balance).div(portfolioBalance);

        return balanceContribution.isFinite() ? balanceContribution.toNumber() : undefined;
      },
    },
  ];

  return (
    <GTable
      columns={columns}
      data={sortedAssetPositions}
      disablePagination
      fullHeight
      stickyHeader
      headerBackground={theme.palette.background.surface}
    />
  );
};

export default PortfolioOpenDerivativePositionList;
