import { Stack, Tooltip } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { bignumber } from 'mathjs';
import { type ReactElement, useState } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  highChartsPositiveColorIndex,
  highChartsNegativeColorIndex,
  tooltipFormat,
  getHighchartColorWithOpacity,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';

import { type ITimeWeightedReturnQuery, useTimeWeightedReturnQuery } from '../../../generated/graphql';
import { formatPercentage } from '../../formatter.utils';
import ActionsChartWithTitle from '../../ActionsChartWithTitle.tsx';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import isNil from 'lodash/fp/isNil';
import type Highcharts from 'highcharts';
import { useFinalColorScheme } from 'useFinalColorScheme.ts';

export const CHART_TITLE = 'Return metrics';

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('profit-loss'),
    ...tooltipFormat,
    yAxis: {
      labels: {
        format: '{value:.2f}%',
      },
    },
  };
};

const calculateChartData = (colorScheme: 'light' | 'dark', data: ITimeWeightedReturnQuery): HighchartSeries[] => {
  return [
    {
      name: 'Time weighted rate of return (TWR)',
      data: data.portfolio.timeWeightedReturns.values.map(({ date, return: returns }) => {
        return {
          x: dayjs.utc(date.toString()).valueOf(),
          y: bignumber(returns.weight).toNumber() * 100,
          textValue: formatPercentage(bignumber(returns.weight).toNumber()),
        };
      }),
      type: 'area',
      zones: [
        {
          value: 0,
          color: getHighchartColorWithOpacity(colorScheme, highChartsNegativeColorIndex, 0.8),
        },
        {
          color: getHighchartColorWithOpacity(colorScheme, highChartsPositiveColorIndex, 0.8), // Color for values less than 0
        },
      ],
    },
  ];
};

const ProfitLossChart = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const colorScheme = useFinalColorScheme();

  // Twr endpoint filters out snapshots by 'since' and 'to' parameters.
  // This unfortunately means that the return for the first day is always 0,
  // we want to change this behaviour to actually show the return compared to the previous day, so
  // we need to subtract 1 day from since. 'To' parameter should stay the same

  const shiftedSinceDateRange: null | [Dayjs, Dayjs] = isNil(dateRange)
    ? null
    : [dateRange[0].subtract(1, 'day'), dateRange[1]];
  const queryOutput = useTimeWeightedReturnQuery({
    variables: {
      ...convertDateRangeToSinceToDate(shiftedSinceDateRange),
      subAccountAssetFilters,
    },
  });

  return (
    <ActionsChartWithTitle title={CHART_TITLE} paper>
      <Stack spacing={1}>
        <Stack direction="row-reverse" justifyContent="space-between" flexWrap="wrap" width="100%" spacing={1.5}>
          <Tooltip title="The dates shown in the intervals are at end of day UTC">
            <div>
              <PredefinedDateRangeButtonsWithCalendar
                defaultValue={defaultDateRange}
                onChange={(val): void => setDateRange(val)}
              />
            </div>
          </Tooltip>
        </Stack>
        <HighChartsContainer<ITimeWeightedReturnQuery>
          {...queryOutput}
          calculateOptions={calculateOptions}
          calculateChartData={(data) => calculateChartData(colorScheme, data)}
        />
      </Stack>
    </ActionsChartWithTitle>
  );
};

export default ProfitLossChart;
