import { Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type FunctionComponent, Suspense, useState } from 'react';
import { useAuth } from 'UseAuth.tsx';
import { parseUtcDate } from 'components/date.utils';
import { getDefaultRange } from 'components/predefinedDateRanges.ts';
import { Select } from 'components/technical/inputs';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';

import {
  IPortfolioDefinitionType,
  IReturnsType,
  type ISupportedDateTimeWindow,
  usePerformanceAttributionInputSuspenseQuery,
} from 'generated/graphql.tsx';
import BottomUpAttributionReport from './BottomUpAttributionReport.tsx';
import BrinsonAttributionReport from './BrinsonAttributionReport.tsx';
import { AggregationMethod, aggregationMethodOptions, returnsTypeOptions } from './PerformanceAttributionService.ts';
import { getClusterOptions } from '../../../market/asset/groups/GroupService.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import {
  createPortfolioDefinitionAutocompleteOptions,
  type PortfolioDefinitionValue,
} from '../../../copilot/lab/PortfolioDefinitionService.tsx';
import StaticSingleAutocomplete from '../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import GErrorBoundary from 'components/technical/GErrorBoundary.tsx';
import { useGenerateKeyOnValueChanged } from '../../../UseGenerateKeyOnValueChanged.tsx';

export type PerformanceAttributionProps = {
  supportedTimeWindow: Pick<ISupportedDateTimeWindow, 'since' | 'until'> | undefined | null;
  supportedClusters: string[];
  portfolioDefinitions: (PortfolioDefinitionValue & {
    type: IPortfolioDefinitionType;
  })[];
};

export const DEFAULT_CLUSTER = 'category';

const PerformanceAttribution: FunctionComponent<PerformanceAttributionProps> = ({
  supportedTimeWindow,
  supportedClusters,
  portfolioDefinitions,
}) => {
  const classificationOptions = getClusterOptions(supportedClusters);
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value());
  const [aggregationMethod, setAggregationMethod] = useState(AggregationMethod.BrinsonFachler);
  const [classification, setClassification] = useState<string>(DEFAULT_CLUSTER);
  const [returnsType, setReturnsType] = useState(IReturnsType.Algebraic);
  const [benchmark, setBenchmark] = useState<null | PortfolioDefinitionValue>(null);
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const { isAdmin } = useAuth();

  const isBottomUpAggregation = aggregationMethod === AggregationMethod.BottomUp;

  const filteredAggregationMethodOptions = isAdmin
    ? aggregationMethodOptions
    : aggregationMethodOptions.filter((option) => option.value !== AggregationMethod.BottomUp);

  const boundaryKey = useGenerateKeyOnValueChanged({
    aggregationMethod,
    subAccountAssetFilters,
    dateRange,
    classification,
    benchmark,
  });

  return (
    <Stack spacing={2} height="100%">
      <Stack direction="row" spacing={1.5} flexWrap="wrap">
        <PredefinedDateRangeButtonsWithCalendar
          alignRight={false}
          defaultValue={defaultDateRange}
          onChange={setDateRange}
          minDate={supportedTimeWindow ? parseUtcDate(supportedTimeWindow.since) : undefined}
          maxDate={supportedTimeWindow ? parseUtcDate(supportedTimeWindow.until) : undefined}
        />
        <Select
          value={classification}
          onChange={setClassification}
          disableClearable
          label="Classification"
          width="normal"
          options={classificationOptions}
        />
        <Select
          value={aggregationMethod}
          onChange={setAggregationMethod}
          disableClearable
          label="Aggregation method"
          width="xl2"
          options={filteredAggregationMethodOptions}
        />
        {isBottomUpAggregation && (
          <Select
            value={returnsType}
            onChange={setReturnsType}
            label="Returns type"
            width="xl2"
            disableClearable
            options={returnsTypeOptions}
          />
        )}
        {isAdmin && (
          <StaticSingleAutocomplete
            value={benchmark}
            onChange={setBenchmark}
            label="Benchmark *"
            width="normal"
            {...createPortfolioDefinitionAutocompleteOptions(portfolioDefinitions)}
          />
        )}
      </Stack>
      <GErrorBoundary key={boundaryKey}>
        {isBottomUpAggregation ? (
          <BottomUpAttributionReport
            subAccountAssetFilters={subAccountAssetFilters}
            dateRange={dateRange}
            classification={classification}
            returnsType={returnsType}
            benchmark={benchmark}
          />
        ) : (
          <Suspense fallback={<Loader />}>
            <BrinsonAttributionReport
              aggregationMethod={aggregationMethod}
              subAccountAssetFilters={subAccountAssetFilters}
              dateRange={dateRange}
              classification={classification}
              benchmark={benchmark}
            />
          </Suspense>
        )}
      </GErrorBoundary>
    </Stack>
  );
};

const PerformanceAttributionContainer: FunctionComponent = () => {
  const { data } = usePerformanceAttributionInputSuspenseQuery();

  const portfolioDefinitions = data.portfolio.definitions.filter(
    (def) => def.type !== IPortfolioDefinitionType.Rebalanced
  );

  return (
    <PerformanceAttribution
      supportedClusters={data.assets.clusterByUser}
      supportedTimeWindow={data.factorRegression.supportedTimeWindow.supportedTimeWindow}
      portfolioDefinitions={portfolioDefinitions}
    />
  );
};
export default PerformanceAttributionContainer;
