import { Stack, Typography } from '@mui/joy';
import type { ColumnDef } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import type { Dayjs } from 'dayjs';
import { bignumber } from 'mathjs';
import { type ReactElement, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  createAccountIdAutocompleteOptions,
  createSubAccountIdAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from 'components/portfolio/account/AccountService.tsx';
import GTable from 'components/technical/GTable/GTable';
import type { Filter } from 'components/technical/GTable/GTable.props';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import DateRangeInput, { type DateRangeInputProps } from 'components/technical/inputs/date/DateRangeInput';
import GInput from 'components/technical/inputs/GInput/GInput.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import {
  IOrderSide,
  ISortDirection,
  type ITransactionFilters,
  IUserTransactionType,
  TransactionFilterInputDocument,
  TransactionsDocument,
  useDeleteTransactionMutation,
  useDownloadTransactionsMutation,
  useTransactionsQuery,
} from '../../../generated/graphql';
import { isValidDayjsDateRange } from '../../date.utils';
import { DateTimeFormat, formatDate, formatEnum } from '../../formatter.utils';
import type { Asset } from '../../market/asset/Asset.types';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import { convertDateRangeToSinceToDateTime } from 'components/technical/inputs/date/dateRange.utils.ts';
import { emptyToUndefined } from './EmptyToUndefined.ts';
import SeeMoreDropdown from '../../technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import UpdateTransactionDialogContainer from './UpdateTransactionDialog.tsx';
import type { Transaction } from './Transaction.types.ts';
import GButton from '../../technical/inputs/GButton/GButton.tsx';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import TransactionLegSummary from './TransactionLegSummary.tsx';
import TransactionStatusChip from './TransactionStatusChip.tsx';
import { DeleteMenuItem } from '../../technical/inputs/GButton/DeleteDialogButton.tsx';
import EditDialogButton from 'components/technical/inputs/GButton/EditDialogButton.tsx';
import TransactionDetailsPanel from './TransactionDetailsPanel.tsx';
import type { StaticMultiAutocompleteProps } from '../../technical/inputs/Autocomplete/StaticMultiAutocomplete.props.ts';
import type { GInputProps } from '../../technical/inputs/GInput/GInput.props.ts';
import { useRegisterActions } from '../../technical/actions/UseRegisterActions.tsx';
import AsyncActionButton from '../../technical/inputs/GButton/AsyncActionButton.tsx';
import { defaultHeaderActionProps } from '../../technical/HeaderBar/DefaultHeaderActionProps.ts';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { downloadTransactionOrderCsv } from '../../file.utils.ts';
import { useFeedback } from '../../technical/Feedback/UseFeedback.tsx';
import { AccountLabel } from '../../portfolio/account/AccountLabel.tsx';

const transactionTypes: StaticAutocompleteOption<string>[] = [
  ...Object.entries(IUserTransactionType).map(([name, value]) => ({
    label: formatEnum(value),
    value: value,
    key: name,
    searchText: formatEnum(value),
  })),
];

const orderSides: StaticAutocompleteOption<string>[] = Object.entries(IOrderSide).map(([name, value]) => ({
  label: name,
  value: value,
  key: name,
  searchText: name,
}));

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const DEBOUNCE_DELAY_MS = 500;

export const TransactionList = ({
  accounts,
  assets,
  existingTags,
}: {
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assets: Asset[];
  existingTags: string[];
}): ReactElement => {
  const executedAtColumnId = 'executedAt';
  const [transactionType, setTransactionTransactionType] = useState<string[]>([]);
  const [side, setSide] = useState<string[]>([]);
  const [accountFilter, setAccountFilter] = useState<string[]>([]);
  const [subAccountFilter, setSubAccountFilter] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [assetFilter, setAssetFilter] = useState<Asset[]>([]);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [commentFilter, setCommentFilter] = useState<string>('');
  const [debouncedCommentFilter, debouncedSetCommentFilter] = useState<string>('');
  const assetOptions = useMemo(() => createAssetSelectOptions(assets), [assets]);
  const accountOptions = useMemo(() => createAccountIdAutocompleteOptions(accounts), [accounts]);
  const subAccountOptions = useMemo(() => createSubAccountIdAutocompleteOptions(accounts), [accounts]);
  const timezone = useUserTimezone();

  useDebounce(
    () => {
      debouncedSetCommentFilter(commentFilter);
    },
    DEBOUNCE_DELAY_MS,
    [commentFilter]
  );

  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const isValidDateRange = isValidDayjsDateRange(dateRange);
  const { since: gte, to: lte } = isValidDateRange
    ? convertDateRangeToSinceToDateTime(dateRange)
    : {
        since: null,
        to: null,
      };

  const filters = useMemo(() => {
    return {
      type: emptyToUndefined(transactionType),
      orderSide: emptyToUndefined(side),
      accountId: emptyToUndefined(accountFilter),
      subAccountId: emptyToUndefined(subAccountFilter.filter(notEmpty).map((subAccount) => subAccount)),
      executedAt: { gte, lte },
      assetIds: emptyToUndefined(assetFilter.map((asset) => asset?.id ?? null)),
      tags: emptyToUndefined(filterTags),
      comment: debouncedCommentFilter ? debouncedCommentFilter : undefined,
    } satisfies ITransactionFilters;
  }, [
    gte,
    lte,
    transactionType,
    side,
    subAccountFilter,
    assetFilter,
    filterTags,
    debouncedCommentFilter,
    accountFilter,
  ]);

  const { tablePaginator, page } = useTablePaginator({
    filters,
  });

  const { showGraphqlError } = useFeedback();
  const [deleteTransaction] = useDeleteTransactionMutation({
    refetchQueries: [TransactionsDocument, TransactionFilterInputDocument],
  });

  const sort = useMemo(
    () => ({
      name: executedAtColumnId,
      direction: sortAscending ? ISortDirection.Asc : ISortDirection.Desc,
    }),
    [sortAscending]
  );

  const { data, loading, error } = useTransactionsQuery({
    variables: {
      filters,
      pageLimit: page,
      sort,
    },
    skip: !isValidDateRange,
  });

  const registerActions = useRegisterActions();

  const [downloadTransactions] = useDownloadTransactionsMutation();

  const handleDownloadTransaction = useCallback(async (): Promise<void> => {
    try {
      const token = await downloadTransactions({
        variables: {
          filters,
          sort,
        },
      });
      downloadTransactionOrderCsv(token.data!.DownloadTransactionsV2.token);
    } catch (e) {
      console.error('Failed to download csv', e);
      showGraphqlError(e);
    }
  }, [filters, sort, downloadTransactions, showGraphqlError]);

  useEffect(
    () =>
      registerActions(
        <AsyncActionButton
          {...defaultHeaderActionProps}
          onClick={handleDownloadTransaction}
          startDecorator={<TableChartOutlinedIcon />}
        >
          Export CSV
        </AsyncActionButton>
      ).deregister,
    [registerActions, handleDownloadTransaction]
  );

  const columns: ColumnDef<Transaction>[] = [
    {
      id: 'accountAndTime',
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        const { subAccount } = props.row.original;
        return (
          <Stack direction={'column'} rowGap={1}>
            <AccountLabel account={subAccount.account} size={IconVariant.XL} />
            <Typography level={'body-xs'}>
              {formatDate(props.row.original.time, DateTimeFormat.DateTime, timezone)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'type',
      cell: (props: CellContext<Transaction, unknown>): ReactNode => (
        <Typography level={'title-sm'}>{formatEnum(props.row.original.userType)}</Typography>
      ),
    },
    {
      id: 'inflow',
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        const inflows = props.row.original.legs.filter((leg) => bignumber(leg.amount).isPositive());
        return <TransactionLegSummary legs={inflows} />;
      },
    },
    {
      id: 'outflow',
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        const outflows = props.row.original.legs.filter((leg) => bignumber(leg.amount).isNegative());
        return <TransactionLegSummary legs={outflows} />;
      },
    },
    {
      id: 'status',
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        return <TransactionStatusChip status={props.row.original.status} />;
      },
    },
    {
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '50px',
        },
        align: 'center',
      },
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        return (
          <Stack justifyContent="center" spacing={1.5}>
            <SeeMoreDropdown>
              <EditDialogButton
                renderDialog={({ onClose }): ReactElement => (
                  <UpdateTransactionDialogContainer
                    onClose={onClose}
                    onAdded={onClose}
                    accounts={accounts}
                    existingTransaction={props.row.original}
                    existingTags={existingTags}
                  />
                )}
              />
              <DeleteMenuItem
                deleteMessage={'Transaction successfully deleted'}
                confirmationMessage={'Are you sure you want to remove transaction?'}
                deleteItem={(): Promise<unknown> => {
                  return deleteTransaction({
                    variables: {
                      input: props.row.original.id,
                    },
                  });
                }}
              />
            </SeeMoreDropdown>
          </Stack>
        );
      },
    },
  ];

  const trans = data?.bookkeeping.transactionsV2;
  const width = 'normal' as const;

  return (
    <GTable
      topBar={
        <GButton onClick={() => setSortAscending(!sortAscending)} variant={'outlined'} color="neutral">
          {sortAscending ? 'Oldest' : 'Most recent'}
        </GButton>
      }
      filters={[
        {
          component: DateRangeInput,
          value: dateRange,
          width: 'xl',
          error: isValidDateRange ? undefined : 'Invalid date',
          onChange: setDateRange,
          label: 'Date',
          defaultValue: null,
          defaultFilter: true,
        } satisfies Filter<DateRangeInputProps>,
        {
          component: StaticMultiAutocomplete,
          options: transactionTypes,
          value: transactionType,
          width,
          limitTags: 1,
          onChange: setTransactionTransactionType,
          label: 'Type',
          defaultValue: [],
          defaultFilter: true,
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          component: StaticMultiAutocomplete,
          ...assetOptions,
          value: assetFilter,
          width,
          menuWidth: 'xl2',
          onChange: setAssetFilter,
          label: 'Asset',
          defaultValue: [],
          defaultFilter: true,
        } satisfies Filter<StaticMultiAutocompleteProps<Asset>>,
        {
          label: 'Order side',
          component: StaticMultiAutocomplete,
          options: orderSides,
          value: side,
          width,
          limitTags: 2,
          onChange: setSide,
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          label: 'Account',
          component: StaticMultiAutocomplete,
          ...accountOptions,
          value: accountFilter,
          width,
          limitTags: 2,
          onChange: setAccountFilter,
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          label: 'Sub-account',
          component: StaticMultiAutocomplete,
          ...subAccountOptions,
          value: subAccountFilter,
          width,
          limitTags: 2,
          onChange: setSubAccountFilter,
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          label: 'Tags',
          component: StaticMultiAutocomplete,
          limitTags: 2,
          options: existingTags.map((tag) => ({
            label: tag,
            value: tag,
            key: tag,
            searchText: tag,
          })),
          value: filterTags,
          width,
          showChipTooltips: true,
          onChange: setFilterTags,
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          label: 'Comment',
          component: GInput,
          width,
          value: commentFilter,
          endDecorator: commentFilter !== debouncedCommentFilter ? <Loader variant="small" /> : null,
          onChange: (val: string | null) => setCommentFilter(val ?? ''),
          defaultValue: '',
        } satisfies Filter<GInputProps>,
      ]}
      columns={columns}
      paginator={tablePaginator}
      totalResults={trans?.pageInfo.totalResults}
      detailRender={TransactionDetailsPanel}
      getRowId={(row) => row.id}
      loading={loading}
      error={error}
      hideHeaders
      data={trans?.data as Transaction[] | undefined}
    />
  );
};
