import { Fragment, type ReactElement, useEffect, useState } from 'react';
import {
  type IPortfolioBacktestingInputQuery,
  usePortfolioBacktestingInputSuspenseQuery,
} from '../../../../generated/graphql.tsx';
import PortfolioBacktestingForm from './PortfolioBacktestingForm.tsx';
import { Stack, Tab, TabList, Tabs } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import { convertDateInUtcToUTCISODate } from '../../../date.utils.ts';
import PortfolioDetails from './details/PortfolioDetails.tsx';
import type { BacktestingConfiguration } from './BacktestConfiguration.types.ts';
import { isNil } from 'lodash/fp';
import { useGenerateKeyOnValueChanged } from '../../../UseGenerateKeyOnValueChanged.tsx';
import PortfolioRisk from './risk/PortfolioRisk.tsx';
import PortfolioResultTab from './PortfolioResultTab.tsx';
import FactorDashboard from '../../factor/FactorDashboard.tsx';
import PortfolioCorrelations from './correlations/PortfolioCorrelations.tsx';
import { useLocation } from 'react-router';
import type { FormInputFields, FormOutputFields } from './PortfolioBacktestingForm.validation.ts';

const DETAILS_TAB = 'details';
const FACTORS_TAB = 'factors';
const RISK_TAB = 'risk';
const CORRELATIONS_TAB = 'correlations';

const PortfolioBacktesting = ({
  definitions,
}: { definitions: IPortfolioBacktestingInputQuery['portfolio']['definitions'] }): ReactElement => {
  const inputQuery = usePortfolioBacktestingInputSuspenseQuery();
  const location = useLocation();
  const [backtestingConfig, setBacktestingConfig] = useState<BacktestingConfiguration | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>('details');

  const normalizedDefs = definitions
    .filter((def) => {
      if (!def.dateRange) {
        console.info('Portfolio definition has no date range', def);
        return false;
      }
      return true;
    })
    .map((def) => ({
      ...def,
      dateRange: {
        since: def.dateRange!.since ?? null,
        to: def.dateRange!.to ?? null,
      },
    }));

  const handleFormSubmit = async (data: FormInputFields): Promise<void> => {
    const output = data as FormOutputFields;

    setBacktestingConfig({
      portfolios: output.portfolios.map((port) => ({
        id: port.portfolio.extraRebalancingSelection ? port.synthPortfolioId! : port.portfolio.id,
        name: port.portfolio.name,
      })),
      range: {
        since: convertDateInUtcToUTCISODate(output.range.since),
        to: output.range.to ? convertDateInUtcToUTCISODate(output.range.to) : null,
      },
    });
  };

  // pass key to clear error in tabs when form values change
  const boundaryKey = useGenerateKeyOnValueChanged(backtestingConfig);
  const disableCorrelations = !isNil(backtestingConfig) && backtestingConfig.portfolios.length < 2;

  useEffect(() => {
    if (activeTab === CORRELATIONS_TAB && disableCorrelations) {
      setActiveTab(DETAILS_TAB);
    }
  }, [activeTab, disableCorrelations]);

  return (
    <Stack direction="column" gap={defaultRowSpacing}>
      <PortfolioBacktestingForm
        definitions={normalizedDefs}
        onSubmit={handleFormSubmit}
        defaultPortfolioIds={(location.state?.portfolioIds as string[]) ?? []}
      />
      {!isNil(backtestingConfig) && (
        <Tabs key={boundaryKey} value={activeTab} onChange={(_e, value) => setActiveTab(value as string)}>
          <TabList>
            <Tab value={DETAILS_TAB}>Details</Tab>
            <Tab value={RISK_TAB}>Risk</Tab>
            <Tab value={FACTORS_TAB}>Factors</Tab>
            <Tab value={CORRELATIONS_TAB} disabled={disableCorrelations}>
              Correlations
            </Tab>
          </TabList>
          <Fragment key={boundaryKey}>
            <PortfolioResultTab value={DETAILS_TAB}>
              <PortfolioDetails
                backtestingConfig={backtestingConfig}
                assetGroups={inputQuery.data.assets.assetGroups}
              />
            </PortfolioResultTab>
            <PortfolioResultTab value={RISK_TAB}>
              <PortfolioRisk backtestingConfig={backtestingConfig} assetGroups={inputQuery.data.assets.assetGroups} />
            </PortfolioResultTab>
            <PortfolioResultTab value={FACTORS_TAB}>
              <FactorDashboard backtestingConfig={backtestingConfig} />
            </PortfolioResultTab>
            <PortfolioResultTab value={CORRELATIONS_TAB}>
              <PortfolioCorrelations backtestingConfig={backtestingConfig} />
            </PortfolioResultTab>
          </Fragment>
        </Tabs>
      )}
    </Stack>
  );
};

const PortfolioBacktestingContainer = (): ReactElement => {
  const inputQuery = usePortfolioBacktestingInputSuspenseQuery();
  return <PortfolioBacktesting definitions={inputQuery.data.portfolio.definitions} />;
};

export default PortfolioBacktestingContainer;
